import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { theme } from "../styles";
import { useStaticQuery, graphql } from "gatsby";
import logo from "../images/onas-logo.svg";
import { LocaleContext } from "./Layout";
import Wrapper from "../components/Wrapper";
import { Desktop, Mobile } from "./Media/Media";

const About = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query aboutPageQuery {
      about: allPrismicHomepage {
        nodes {
          lang
          data {
            oddil {
              sekce_polozka {
                document {
                  ... on PrismicProductSection {
                    id
                    data {
                      nadpis {
                        raw {
                          text
                        }
                      }
                      obsah {
                        html
                      }
                      foto {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const aboutData = data.about.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const aboutQuery = aboutData[0];

  console.log(aboutQuery.oddil[3].sekce_polozka.document[0].data);

  function createMarkup() {
    return {
      __html: aboutQuery.oddil[3].sekce_polozka.document[0].data.obsah.html,
    };
  }

  return (
    <>
      <div
        className="background"
        style={{ backgroundColor: "#133c8b", width: "100vw" }}
      >
        <Wrapper>
          <div className={className}>
            <div className="about" id="o-nas">
              <div className="about-header" id="About">
                <span className="title-logo">
                  <img src={logo} alt="title-logo" />
                </span>
                {aboutQuery &&
                aboutQuery.oddil[3].sekce_polozka.document[0].data.nadpis ? (
                  <h2>
                    {
                      aboutQuery.oddil[3].sekce_polozka.document[0].data.nadpis
                        .raw[0].text
                    }
                  </h2>
                ) : (
                  ""
                )}
              </div>
              {aboutQuery.oddil[3].sekce_polozka.document[0].data &&
              aboutQuery.oddil[3].sekce_polozka.document[0].data.obsah ? (
                <div className="about-info">
                  <div dangerouslySetInnerHTML={createMarkup()}></div>
                </div>
              ) : (
                ""
              )}
              <div className="about-image">
                {aboutQuery &&
                aboutQuery.oddil[3].sekce_polozka.document[0].data.foto ? (
                  <>
                    <Desktop>
                        <Img
                          fluid={
                            aboutQuery.oddil[3].sekce_polozka.document[0].data
                              .foto.localFile.childImageSharp.fluid
                          }
                        />
                    </Desktop>
                    <Mobile>
                      <Img
                        fluid={
                          aboutQuery.oddil[3].sekce_polozka.document[0].data
                            .foto.localFile.childImageSharp.fluid
                        }
                      />
                    </Mobile>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            {children}
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default styled(About)`
  display: grid;
  grid-template-columns: 1fr;

  .about {
    text-align: center;
    // background-color: ${theme.colors.primary};
    // width: 100vw;
  }
  .about h2 {
    padding-top: 50px;
    border-bottom: 1px solid;
    color: white;
    padding-bottom: 10px;
  }

  .about-info p {
    color: white;
    margin-bottom: 10px;
  }

  .about-info {
    padding: 20px;
  }

  .about-header span {
    display: none;
  }

  .about-info a {
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    font-weight: 600;
  }

  @media (min-width: 1025px) {
    .about {
      width: 100%;
      height: 874px;
      margin-top: 20px;
      text-align: center;
      position: relative;
    }

    .about h2 {
      padding-top: 80px;
      margin-left: 145px;
      border-bottom: 1px solid;
      text-align: left;
      padding-bottom: 3px;
      font-size: 36px !important;
      font-weight: 700;
    }

    .about-header span {
      display: inline;
      position: absolute;
      left: 20px;
      padding-top: 80px;
    }

    .about-info {
      max-width: 1000px;
      margin: 0 auto;
      width: 64%;
      padding-top: 40px;
      padding-bottom: 50px;
    }
    .about-info p {
      font-size: 24px;
    }

    .about-info a {
      text-decoration: none;
      color: white;
      text-transform: capitalize;
      font-weight: 700;
    }

    .about-image {
      width: 911px;
      margin: 0 auto;
      box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
        0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`;
