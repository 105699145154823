import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "../Layout";
import Wrapper from "../Wrapper";
import LocalizedLink from "../LocalizedLink";
// import { theme } from "../styles";


const ProductCardNitra = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      singleProduct: allPrismicHomepage {
        nodes {
          lang
          data {
            oddil {
              sekce_polozka {
                document {
                  ... on PrismicOddilKarty {
                    data {
                      footer_image_text {
                        raw {
                          text
                          spans {
                            data {
                              uid
                            }
                          }
                        }
                      }
                      nadpis {
                        raw {
                          text
                        }
                      }
                      obsah {
                        text
                      }
                      foto {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const productData = data.singleProduct.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });
  const productQuery = productData[0];

  return (
    <Wrapper>
       
      <section className={className}>
        <div className="card-nitra">
          <div className="card-nitra-image-container">
            {productQuery &&
            productQuery.oddil[4].sekce_polozka.document[0].data.foto ? (
              <div className="card-nitra-image">
                <Img
                  fluid={
                    productQuery.oddil[4].sekce_polozka.document[0].data.foto
                      .localFile.childImageSharp.fluid
                  }
                  objectFit="cover"
                />
              </div>
            ) : (
              ""
            )}
            {/* <div className="card-nitra-text-body">
              {productQuery &&
              productQuery.oddil[4].sekce_polozka.document[0].data
                .footer_image_text ? (
                <>
                  {productQuery.oddil[4].sekce_polozka.document[0].data.footer_image_text.raw.map(
                    (item, index) => {
                      return (
                        <div key={index} className="card-nitra-text-legend">
                          <p>{item.text}</p>
                        </div>
                      );
                    }
                  )}
                </>
              ) : (
                ""
              )}
            </div> */}
          </div>
          <div className="card-nitra-body">
            <div className="card-nitra-title">
              {productQuery &&
              productQuery.oddil[4].sekce_polozka.document[0].data.nadpis ? (
                <h3>
                  {
                    productQuery.oddil[4].sekce_polozka.document[0].data.nadpis
                      .raw[0].text
                  }
                </h3>
              ) : (
                ""
              )}
            </div>
            <div className="card-nitra-description">
            {productQuery &&
            productQuery.oddil[4].sekce_polozka.document[0].data.obsah ? (
              <p>
                {
                  productQuery.oddil[4].sekce_polozka.document[0].data.obsah
                    .text
                }
              </p>
            ) : (
              ""
            )}
            <br />
            {i18n.locale === "cs-cz" ? (
              <p className="card-nitra-description-links">
                Více naleznete na: 
                <LocalizedLink to="/#Kontakty">
                  VELETRŽNÍ LOGISTIKA ČESKÁ REPUBLIKA – kancelář Praha
                </LocalizedLink>{" "}
                nebo{" "}
                <LocalizedLink to="/#kontakty-nitra">
                  VELETRŽNÍ LOGISTIKA SLOVENSKO – kancelář Nitra.
                </LocalizedLink>
                 
              </p>
            ) : (
              <p className="card-nitra-description-links">
                Find out more at:{" "}
                <LocalizedLink to="/#Kontakty">
                  EXHIBITION LOGISTICS CZECH REPUBLIC – Prague office
                </LocalizedLink>{" "}
                or{" "}
                <LocalizedLink to="/#kontakty-nitra">
                  EXHIBITION LOGISTICS SLOVAKIA – Nitra office
                </LocalizedLink>
                 
              </p>
            )}
            </div>
            {/* <div className="card-nitra-text-mobile">
              {productQuery &&
              productQuery.oddil[4].sekce_polozka.document[0].data
                .footer_image_text ? (
                <>
                  {productQuery.oddil[4].sekce_polozka.document[0].data.footer_image_text.raw.map(
                    (item, index) => {
                      return <p className="card-nitra-text-legend" key={index}>{item.text}</p>;
                    }
                  )}
                </>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      </section>
      {children}

    </Wrapper>
  );
};

export default styled(ProductCardNitra)`
  /* nitra card */

  .card-nitra-text-body {
    padding-top: 15px;
  }

  .card-nitra-image {
    display: none;
  }

  .card-nitra-text-legend {
    font-size: 10px !important;
    text-align: left;

    p {
      font-size: 10px !important;
    }
  }

  .card-nitra-text {
    display: none;
    font-size: 10px;
  }

  .card-nitra-title {
    padding-top: 50px;
    margin: 0 auto;
    width: 80%;
    text-align: center;
  }

  .card-nitra-description {
    text-align: justify;
    padding: 20px;
  }

  .card-nitra-description-links {
    text-align: justify;
    a {
      text-decoration: none !important;
      border: none !important;
      :hover {
        text-decoration: none !important;
        border: none !important;
        background: none !important;
        padding-bottom: none !important;
        font-weight: 600;
      }
    }
  }

  .card-nitra-text-mobile p {
    font-size: 10px !important;
    padding: 10px;
    margin-bottom: 0;
    padding-bottom: 0px;
    line-height: 0.5;
  }

  .gatsby-image-wrapper {
    width: 400px;
    height: 300px;
    box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
      0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 1025px) {
    .card-nitra {
      margin-top: 100px;
      margin-left: 115px;
      display: flex;
    }

    .card-nitra-body {
      padding-left: 50px;
      max-width: 600px;

      a {
        text-decoration: none !important;
        border: none !important;
        :hover {
          text-decoration: none !important;
          border: none !important;
          background: none !important;
          padding-bottom: none !important;
          font-weight: 600;
        }
      }
    }
    .gatsby-image-wrapper {
      width: 500px;
      height: 400px;
    }
    .card-nitra-image {
      display: inline !important;
    }

    .card-nitra-title {
      // max-width: 780px;
      padding: 0;
      margin: 0;
      text-align: left;
      width: 100%;
    }

    .card-nitra-title h3 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 28px;
    }

    .card-nitra-text-mobile {
      display: none;
    }

    .card-nitra-text {
      display: inline;
      text-align: left;
    }

    .card-nitra-text p {
      padding-left: 18px;
      margin-bottom: 0;
    }

    .card-nitra-image p {
      padding-left: 15px;
      font-size: 14px;
    }

    .card-nitra-text-body {
      padding-top: 15px;
      padding-left: 15px;
    }

    .card-nitra-description {
      padding: 0;
      width: 100%;
    }
    .card-nitra-description-links {
      padding: 0;
      width: 100%;
      a {
        text-decoration: none !important;
        border: none !important;
        :hover {
          text-decoration: none !important;
          border: none !important;
          background: none !important;
          padding-bottom: none !important;
          font-weight: 600;
        }
      }
    }

    .card-nitra-description p {
      text-align: justify;
      /* margin-right: 154px; */
      word-break: break-word;
      font-size: 24px;
    }
  }

  @media (min-width: 1440px) {
    .card-nitra-body {
      max-width: 840px;
    }

    .card-nitra-title h3 {
      margin-bottom: 30px;
      // font-size: 36px !important;
    }

    .card-nitra-text p {
      padding-left: 18px;
      margin-bottom: 0;
      // font-size: 18px !important;
    }
  }
`;
