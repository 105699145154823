import React from "react";
import styled from "styled-components";
import IelaLogo from "../images/iela-transparent.svg";

const Banner = ({ title, info, children, className }) => {
  return (
    <div className={className}>
      <div className="banner-body">
        <div className="banner-title">
          <h1>{title}</h1>
        </div>
        <div className="banner-info">
          <p>
          <img src={IelaLogo} alt="iela-logo" className="iela-logo"/>

          {info}
          </p>
          
          {/* <IelaLogo className="iela-logo"/>  */}
        </div>
        {children}
      </div>
    </div>
  );
};

export default styled(Banner)`
height: 100%;
margin: 0 auto;
max-width: 1440px;
}

  .banner-body {
    max-width: 463.99px;
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  .iela-logo {
    width: 40px;
    // height: 40px;
    margin-right: 10px;
  }

  .banner-title {
    // padding-top: 101px;
    padding-left: 21px;
  }

  .banner-title h1 {
    color: white;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 22px!important;
    padding-bottom: 13px;
    background: linear-gradient(white, white) bottom left
      /* left or right or else */ no-repeat;
    background-size: 85% 1px;
  }

  .banner-info {
    padding-left: 21px;
  }

  .banner-info p {
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items:center;
    margin-top: 5px;
  }

  @media (min-width: 1025px) {
    .banner-body {
      max-width: 860px;
      // position: absolute;
      // bottom: 40px;
      // left: 95px;
    }

    .banner-title h1 {
      font-size: 50px !important;
      color: white;
      font-weight: 300;
      text-transform: uppercase;
      border-bottom: 1px solid;
    }

    .banner-info p {
      color: white;
      text-transform: uppercase;
      font-size: 24px;
      // padding-left: 10px;
      margin-top: 20px;
    }
  }
`;
