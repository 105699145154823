import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { useStaticQuery, graphql } from "gatsby";
import { theme } from "../styles";
import logo from "../images/title-logo.svg";
import { LocaleContext } from "./Layout";
import Wrapper from "../components/Wrapper";

const Parteneri = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      parteneri: allPrismicPartneri {
        nodes {
          lang
          data {
            partneri_nadpis {
              raw {
                text
              }
            }
            partneri_list {
              partneri_logo {
                localFile {
                  childImageSharp {
                    fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const parteneriData = data.parteneri.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const parteneriQuery = parteneriData[0];

  return (
    <>
      <Wrapper>
        <div
          className="smooth-scrolling"
          id="Partneři"
          styles={{ paddingTop: "30px" }}
        ></div>

        <section
          className={className}
          id="Partneři"
          id="Partenaire"
          style={{ paddingTop: "30px" }}
        >
          <div className="parteneri-header">
            <span className="title-logo">
              <img src={logo} alt="title-logo" />
            </span>
            {parteneriQuery && parteneriQuery.partneri_nadpis ? (
              <h2>{parteneriQuery.partneri_nadpis.raw[0].text}</h2>
            ) : (
              ""
            )}
          </div>
          <div className="parteneri-body">
            <div className="parteneri-img">
              {parteneriQuery && parteneriQuery.partneri_list ? (
                <>
                  {parteneriQuery.partneri_list.map((item, index) => {
                    return (
                      <div key={index} className="parteneri-image-list">
                        <Img
                          fixed={
                            item.partneri_logo.localFile.childImageSharp.fixed
                          }
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default styled(Parteneri)`
  .parteneri-header {
    text-align: center;
    margin-top: 50px;
  }

  .parteneri-img {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    align-items: center;
    justify-content: center;
  }

  .parteneri-header h2 {
    border-bottom: 1px solid;
    padding-bottom: 10px;
  }

  .parteneri-header span {
    display: none;
  }

  .parteneri-body {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 50px;
  }

  @media (min-width: 1025px) {
    .parteneri-header {
      margin-left: 145px;
      margin-top: 60px;
      margin-bottom: 0px;
      text-align: left;
      position: relative;
    }

    .parteneri-img {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 100px;
      align-items: center;
    }

    .parteneri-header span {
      display: inline;
      position: absolute;
      left: -125px;
    }

    .parteneri-header h2 {
      font-size: 36px !important;
      font-weight: 700;
      letter-spacing: 2px;
      padding-bottom: 5px;
    }

    .parteneri-body {
      margin: 0 auto;
      padding: 116px;
      margin-top: -55px;
    }

    .parteneri-img {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      text-align: center;
      grid-row-gap: 40px;
      align-items: center;
      margin-bottom: 0px;
    }
  }
`;
