import React, { useState, Fragment } from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { theme } from "../styles";
import { useStaticQuery, graphql } from "gatsby";
import { Transition } from "react-transition-group";
import { LocaleContext } from "./Layout";
import Wrapper from "../components/Wrapper"

const ProductCards = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicCards {
        nodes {
          lang

          data {
            productcards {
              cardimage {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              cardinfo {
                raw {
                  text
                }
              }
              cardtitle {
                raw {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const [showInfo, setInfo] = useState(false);

  const toggleInfo = (id) => {
    setInfo((prevShowInfo) => ({
      ...prevShowInfo,
      [id]: !prevShowInfo[id],
    }));
  };

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const productCards = data.allPrismicCards.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const productCardsQuery = productCards[0];

  return (
    <Wrapper>
      <section className={className}>
        <div className="card">
          <div className="card-container">
            {productCards && productCardsQuery.productcards ? (
              <>
                {productCardsQuery.productcards.map((product, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="grid-item">
                        <div className="image">
                          <BackgroundImage
                            onClick={() => toggleInfo(index)}
                            fluid={
                              product.cardimage.localFile.childImageSharp.fluid
                            }
                            className="background-image"
                          >
                            {product.cardtitle.raw[0].text ? (
                              <h3>{product.cardtitle.raw[0].text}</h3>
                            ) : null}
                          </BackgroundImage>
                          <div
                            className={
                              showInfo[index] ? "show-info" : "card-info"
                            }
                          >
                            {showInfo[index] ? (
                              <p>{product.cardinfo.raw[0].text}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      {children}
    </Wrapper>
  );
};

export default styled(ProductCards)`
  .card {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1114px;
    margin: 0 auto;
  }

  .card-container {
    display: inline-grid;
    grid-column-gap: 136px;
    grid-row-gap: 20px;
    margin-bottom: 20px;
  }

  .background-image {
    opacity: 1 !important;

    height: 325px;
    background: rgba(255, 255, 255, 0.5);
    // border-bottom: 1px solid ${theme.colors.primary};
    // border-width: 6px;
    height: 92px;
  }

  .background-image h3 {
    position: relative;
    top: 45%;
    text-align: center;
    font-size: 20px!important;
    font-weight: 700;
  }

  .card-info {
    list-style-type: none;
    transition: all 0.4s linear;
    min-height: auto;
    overflow: hidden;
  }

  .card-info p {
    padding-top: 30px;
    padding-top: 30px;
  }

  .show-info {
    padding-top: 30px;
    padding-top: 30px;
    height: auto;
    text-align: left;
    transition: all 0.4s linear;
    overflow: hidden;
  }

  .show-info p {
    // border-bottom: 3px solid;
    padding-bottom: 40px;
    word-break: break-word;
    text-align: justify;
    padding: 20px 20px 35px;
  }

  @media (min-width: 510px) {
    .background-image {
      height: 300px;
    }
  }

  @media (min-width: 1025px) {
    .card {
      // padding: 20px;
      margin-bottom: 50px;
      margin-top: 100px;
    }
    .card-container {
      display: inline-grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 136px;
      grid-row-gap: 100px;
    }

    .background-image h3 {
      position: relative;
      top: 45%;
      text-align: center;
      font-size: 28px!important;
    }

    .card-info {
      list-style-type: none;
      transition: all 0.4s linear;
      min-height: auto;
      overflow: hidden;
      padding-left: 9px;
    }

    .show-info {
      height: auto;
      padding-top: 30px;
      // padding-left: 9px;
      text-align: left;
      max-width: 500px;
      transition: all 0.4s linear;
      overflow: hidden;
    }

    .show-info p {
      padding: 0;
      background: none;
      // border-bottom: 3px solid;
      // padding-bottom: 50px;
    }

    .background-image {
      opacity: 1 !important;

      height: 325px;
      background: rgba(255, 255, 255, 0.5);
      // border-bottom: 1px solid ${theme.colors.primary};
      // border-width: 6px;
      height: 325px;
    }
  }

  .image:hover {
    cursor: pointer;
  }
`;
