import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "../Layout";
import Wrapper from "../Wrapper";
import LocalizedLink from "../LocalizedLink";

// import { theme } from "../styles";

const ProductCardBruno = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      singleProduct: allPrismicHomepage {
        nodes {
          lang
          data {
            oddil {
              sekce_polozka {
                document {
                  ... on PrismicOddilKarty {
                    data {
                      footer_image_text {
                        raw {
                          text
                        }
                      }
                      nadpis {
                        raw {
                          text
                        }
                      }
                      obsah {
                        raw {
                          text
                        }
                      }
                      foto {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const productData = data.singleProduct.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const productQuery = productData[0];

  return (
    <Wrapper>
      <section className={className}>
        <div className="card-bruno">
          <div className="card-bruno-body">
            <div className="hide-in-mobile-view">
              <div className="card-bruno-image-container">
                <div className="card-bruno-image">
                  {productQuery &&
                  productQuery.oddil[2].sekce_polozka.document[0].data.foto ? (
                    <Img
                      fluid={
                        productQuery.oddil[2].sekce_polozka.document[0].data
                          .foto.localFile.childImageSharp.fluid
                      }
                      objectFit="cover"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="brno-description-container">
                <div className="card-bruno-title">
                  {productQuery &&
                  productQuery.oddil[2].sekce_polozka.document[0].data
                    .nadpis ? (
                    <h3>
                      {
                        productQuery.oddil[2].sekce_polozka.document[0].data
                          .nadpis.raw[0].text
                      }
                    </h3>
                  ) : (
                    ""
                  )}
                </div>
                <div className="card-bruno-description">
                  {productQuery &&
                  productQuery.oddil[2].sekce_polozka.document[0].data.obsah ? (
                    <p>
                      {
                        productQuery.oddil[2].sekce_polozka.document[0].data
                          .obsah.raw[0].text
                      }
                    </p>
                  ) : (
                    ""
                  )}{" "}
                  <br />
                  {i18n.locale === "cs-cz" ? (
                    <p>
                      Více naleznete na: 
                      <LocalizedLink to="/#kontakty-brno">
                        VELETRŽNÍ LOGISTIKA V ZAHRANIČÍ – kancelář Brno
                      </LocalizedLink>{" "}
                    </p>
                  ) : (
                    <p>
                      Find out more at:{" "}
                      <LocalizedLink to="/#kontakty-brno">
                        EXHIBITION LOGISTICS WORLDWIDE – Brno office
                      </LocalizedLink>{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="hide-in-desktop-view">
              <div className="card-bruno-title">
                {productQuery &&
                productQuery.oddil[2].sekce_polozka.document[0].data.nadpis ? (
                  <h3>
                    {
                      productQuery.oddil[2].sekce_polozka.document[0].data
                        .nadpis.raw[0].text
                    }
                  </h3>
                ) : (
                  ""
                )}
              </div>
              <div className="card-bruno-description">
                {productQuery &&
                productQuery.oddil[2].sekce_polozka.document[0].data.obsah ? (
                  <p>
                    {
                      productQuery.oddil[2].sekce_polozka.document[0].data.obsah
                        .raw[0].text
                    }
                  </p>
                ) : (
                  ""
                )}
                <br />
                {i18n.locale === "cs-cz" ? (
                  <p>
                    Více naleznete na: 
                    <LocalizedLink to="/#kontakty-brno">
                      VELETRŽNÍ LOGISTIKA V ZAHRANIČÍ – kancelář Brno
                    </LocalizedLink>{" "}
                  </p>
                ) : (
                  <p>
                    Find out more at:{" "}
                    <LocalizedLink to="/#kontakty-brno">
                      EXHIBITION LOGISTICS WORLDWIDE – Brno office
                    </LocalizedLink>{" "}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="card-bruno-text">
              {productQuery &&
              productQuery.oddil[2].sekce_polozka.document[0].data
                .footer_image_text ? (
                <div className="card-bruno-img-info">
                  {productQuery.oddil[2].sekce_polozka.document[0].data.footer_image_text.raw.map(
                    (item, index) => {
                      return <p className="card-bruno-text-legend" key={index}>{item.text}</p>;
                    }
                  )}
                </div>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      </section>
      {children}
    </Wrapper>
  );
};

export default styled(ProductCardBruno)`
  /* nitra card */
  display: flex;
  text-align: center;

  .card-bruno {
  }

  .card-bruno-text-legend {
    font-size: 10px !important;
  }

  .hide-in-desktop-view {
    display: inline;
  }

  .hide-in-mobile-view {
    display: none;
  }

  .card-bruno-title {
    padding-top: 50px;
    margin: 0 auto;
    // width: 80%;
  }

  .card-bruno-title h2 {
    font-size: 18px !important;
  }

  .gatsby-image-wrapper {
    box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
      0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .card-bruno-description {
    text-align: justify;
    font-size: 14px !important;
    padding: 20px;

    a {
      text-decoration: none !important;
      border: none !important;
      :hover {
        text-decoration: none !important;
        border: none !important;
        background: none !important;
        padding-bottom: none !important;
        font-weight: 600;
      }
    }
  }

  @media (min-width: 1025px) {
    // .card-bruno-title h2 {
    //   font-size: 28px !important;
    // }

    .brno-description-container {
      display: flex;
      flex-direction: column;
      padding-top: 0px;
      padding-left: 50px;
      max-width: 600px;
    }
    .card-bruno {
      display: flex;
      margin-top: 100px;
      margin-left: 115px;
      flex-direction: row-reverse;
    }
    .card-bruno-image {
      display: inline !important;
    }

    .gatsby-image-wrapper {
      width: 500px;
      height: 400px;
    }

    .hide-in-desktop-view {
      display: none;
    }

    .card-bruno-title h3 {
      margin-bottom: 20px;
      font-weight: 700;
    }

    .hide-in-mobile-view {
      display: flex;
    }

    .card-bruno-title {
      margin: 0;
      padding-top: 0;
    }

    .card-bruno-text {
      display: inline;
      text-align: right;
      font-size: 10px;
    }

    .card-bruno-text p {
      margin-bottom: 0;
      max-width: 440px;
    }

    .card-bruno-description {
      word-break: break-word;
      text-align: justify;
      padding: 0;
      width: 100%;

      a {
        text-decoration: none !important;
        border: none !important;
        :hover {
          text-decoration: none !important;
          border: none !important;
          background: none !important;
          padding-bottom: none !important;
          font-weight: 600;
        }
      }
    }

    .card-bruno-body {
    }

    .card-bruno-title {
      text-align: left;
    }

    .card-bruno-img-info {
      padding-top: 36px;

      p {
        font-size: 14px;
      }
    }

    .card-bruno-image-container {
    }
  }

  @media (min-width: 1440px) {
    .card-bruno-title h3 {
      margin-bottom: 30px;
      // font-size: 36px !important;
    }

    .brno-description-container {
      max-width: 840px;
    }

    .card-bruno-text p {
      margin-bottom: 0;
      // font-size: 14px !important;
      max-width: 440px;
    }
  }
`;
