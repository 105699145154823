import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { Wrapper, Title } from "../components";
// import website from "../../config/website";
import { LocaleContext } from "../components/Layout";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Navbar from "../components/Navbar/Navbar";
import Banner from "../components/Banner";
import StyledHero from "../components/StyledHero";
import Product from "../components/Product";
import ProductCards from "../components/ProductCards";
import ProductCardNitra from "../components/ProductCards/ProductCardNitra";
import ProductCardBruno from "../components/ProductCards/ProductCardBruno";
import About from "../components/About";
import BranchPraha from "../components/Branches/BranchePraha";
import BranchBruno from "../components/Branches/BranchBruno";
import BranchNitra from "../components/Branches/BranchNitra";
import Footer from "../components/Footer";
import Parteneri from "../components/Parteneri";
import { Link } from "gatsby";
import ScrollUpButton from "react-scroll-up-button";

const Index = ({ data: { homepage }, pageContext: { locale }, location }) => {
  console.log(locale);
  return (
    <>
      <ScrollUpButton ContainerClassName="scroll-up-button" />
      <SEO pathname={location.pathname} locale={locale} />
      <Navbar isCurent></Navbar>
      <StyledHero
        home="true"
        img={
          homepage.data.banner_image.localFile.childImageSharp.fluid
            ? homepage.data.banner_image.localFile.childImageSharp.fluid
            : ""
        }
      >
        <Banner
          title={
            homepage.data.title.raw[0].text
              ? homepage.data.title.raw[0].text
              : ""
          }
          info={
            homepage.data.body_image.raw[0].text
              ? homepage.data.body_image.raw[0].text
              : ""
          }
        ></Banner>
      </StyledHero>
      <Product data={homepage.data}></Product>
      <ProductCardNitra></ProductCardNitra>
      <ProductCardBruno></ProductCardBruno>
      <ProductCards></ProductCards>
      <About></About>
      <BranchPraha />
      <BranchBruno />
      <BranchNitra />
      <Parteneri />
      <Footer>
        {locale === "en-us" ? (
          <div className="footer">
            <div className="top-footer">
              <p>
                CENTRUMSPED s.r.o. <br /> Výstaviště 416, Bubeneč <br /> 170 00
                Praha 7 <br /> IČ: 47124598 <br /> DIČ: CZ47124598 <br />{" "}
                (VAT-TAX No., EORI No.)
              </p>
            </div>
            <div className="bottom-footer">
              <Link to="/en/CopyRight">
                <p>Legal notice</p>
              </Link>
              <p>
                ©2020 CENTRUMSPED s.r.o. <br />
                All rights reserved.
              </p>
            </div>
          </div>
        ) : (
          <div className="footer">
            <div className="top-footer">
              <p>
                CENTRUMSPED s.r.o. <br /> Výstaviště 416, Bubeneč <br /> 170 00
                Praha 7 <br /> IČ: 47124598 <br /> DIČ: CZ47124598 <br />{" "}
                (VAT-TAX No., EORI No.)
              </p>
            </div>
            <div className="bottom-footer">
              <Link to="/CopyRight">
                <p> Právní upozornění</p>
              </Link>
              <p>
                ©2020 CENTRUMSPED s.r.o. <br /> Všechna práva vyhrazena.
              </p>
            </div>
          </div>
        )}
      </Footer>
    </>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        oddil {
          sekce_polozka {
            document {
              ... on PrismicHeader {
                data {
                  links {
                    produkty {
                      raw {
                        text
                      }
                    }
                  }
                  logo {
                    localFile {
                      extension
                      publicURL
                    }
                  }
                }
              }
              ... on PrismicOddilKarty {
                data {
                  nadpis {
                    raw {
                      text
                    }
                  }
                  obsah {
                    raw {
                      text
                    }
                  }
                  foto {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  footer_image_text {
                    raw {
                      text
                    }
                  }
                }
              }
              ... on PrismicProductSection {
                data {
                  nadpis {
                    raw {
                      text
                    }
                  }
                  obsah {
                    raw {
                      text
                    }
                  }
                  foto {
                    localFile {
                      childImageSharp {
                        fixed {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        title {
          raw {
            text
          }
        }
        body_image {
          raw {
            text
          }
        }
        banner_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    social: allPrismicHeroLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                label {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
