import React, { Children } from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import logo from "../images/title-logo.svg";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "./Layout";
import Wrapper from "../components/Wrapper";
import { Desktop, Mobile } from "./Media/Media"

const Product = ({ className, children, homepage }) => {
  const data = useStaticQuery(graphql`
    query ProductQuery {
      product: allPrismicHomepage {
        nodes {
          lang
          data {
            oddil {
              sekce_polozka {
                document {
                  ... on PrismicProductSection {
                    data {
                      foto {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                      nadpis {
                        raw {
                          text
                        }
                      }
                      obsah {
                        raw {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const productData = data.product.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const productQuery = productData[0];

  return (
    <Wrapper>
      <div className={className} id="Produkty">
        <div className="product" id="Product">
          <div className="product-title">
            <span className="product-title-logo">
              <img src={logo} alt="title-logo" />
            </span>
            {productQuery &&
            productQuery.oddil[1].sekce_polozka.document[0].data.nadpis ? (
              <h2>
                {
                  productQuery.oddil[1].sekce_polozka.document[0].data.nadpis
                    .raw[0].text
                }
              </h2>
            ) : (
              ""
            )}
          </div>
          {productQuery &&
          productQuery.oddil[1].sekce_polozka.document[0].data.obsah ? (
            <p>
              {
                productQuery.oddil[1].sekce_polozka.document[0].data.obsah
                  .raw[0].text
              }
            </p>
          ) : (
            ""
          )}
        </div>
          <div className="product-image">
            {productQuery &&
            productQuery.oddil[1].sekce_polozka.document[0].data.foto ? (
              <>
              <Desktop>
              <Img
                fluid={
                  productQuery.oddil[1].sekce_polozka.document[0].data.foto
                    .localFile.childImageSharp.fluid
                }
                />
                </Desktop>
                <Mobile> <Img
                fluid={
                  productQuery.oddil[1].sekce_polozka.document[0].data.foto
                    .localFile.childImageSharp.fluid
                }
                /></Mobile>
                </>
            ) : (
              ""
            )}
          </div>

        {children}
      </div>
    </Wrapper>
  );
};

export default styled(Product)`
  display: grid;
  grid-template-columns: 1fr;
  // padding-top: 50px;

  .product span {
    display: none;
  }

  .product h2 {
    border-bottom: 1px solid;
    letter-spacing: 2px;
    padding-bottom: 16px;
    text-align: center;
  }

  .gatsby-image-wrapper {
    vertical-align: middle;
  }

  .product-image {
    box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
      0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    width: 100%;
    max-width: 600px;
  }

  .product p {
    padding: 20px;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 1025px) {
    // padding-top: 50px;
    min-height: 400px;

    .product {
      margin-left: 145px;
      text-align: left;
    }

    .product span {
      // position: absolute;
      // left: 20px;
      // bottom: 20px;
      display: inline;
    }

    .product-title {
      display: flex;
      // align-items: center;
      // justify-content: left;
      margin-left: -145px;
    }

    .product p {
      max-width: 35%;
      word-break: break-word;
      margin-bottom: 0px;
      margin-top: 40px;
      font-size: 16px !important;
      padding: 0px;
      margin-left: -30px;
    }

    .product h2 {
      border-bottom: 1px solid;
      text-align: left;
      // padding-bottom: 5px;
      font-size: 36px !important;
      font-weight: 700;
      width: 100%;
      margin-left: 10px;
    }

    .product-image {
      box-shadow: none;
      width: 50%;
      left: 50%;
      position: absolute;
      max-width: 600px;
    }
  }

  @media (min-width: 1440px) {
    min-height: 400px;
    .product {
      margin-left: 145px;
      text-align: left;
    }

    .product span {
      // position: absolute;
      // left: 10px;
      // padding-top: 10px;
      display: inline;
    }

    .product p {
      max-width: 450px;
      word-break: break-word;
    }

    .product h2 {
      border-bottom: 1px solid;
      padding-bottom: 3px;
      // margin-bottom: 65px;
    }

    .product-image {
      box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
        0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 50%;
      left: 50%;
      position: absolute;
      max-width:600px;
    }
  }

  .gatsby-image-wrapper {
    vertical-align: middle;
  }

  // @media (min-width: 1680px) {
  //   height: 550px;
  // }

  // @media (min-width: 1920px) {
  //   height: 600px;
  // }

  @media (min-width: 2500px) {
    // height: 400px;
  }
`;
