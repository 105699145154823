import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { LocaleContext } from "../Layout";

import { useStaticQuery, graphql } from "gatsby";
import { theme } from "../../styles";
import { FaSquareFull } from "react-icons/fa";
import logo from "../../images/title-logo.svg";
import Wrapper from "./../Wrapper";


const BranchePraha = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      branch: allPrismicKontakty(
        filter: { uid: { eq: "kontakt-kancelar-brno" } }
      ) {
        nodes {
          lang
          data {
            kontakty_list_title {
              raw {
                text
              }
            }
            kontakty_nadpis {
              raw {
                text
              }
            }
            kontakty_obsah {
              raw {
                text
              }
            }
            kontakty_subtitle {
              raw {
                text
              }
            }
            kontakt_napsah {
              raw {
                text
              }
            }
            kontakt_adresa {
              text
              html
            }
            kontakt_email {
              text
            }
            kontakt_email_2 {
              text
            }
            kontakt_email_3 {
              text
            }
            kontakt_name_2 {
              text
            }
            kontakt_name_3 {
              text
            }
            kontakt_tel {
              text
            }
            kontakt_tel_2 {
              text
            }
            kontakt_tel_3 {
              text
            }
            kontakt_kontakt {
              text
              html
            }
            kontakty_list {
              kontakty_item {
                raw {
                  text
                }
              }
            }
            kontakty_image_group {
              kontakty_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const settings = {
    infinite: true,
    fade: true,
    speed: 500,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          autoplay: false,
        },
      },
    ],
  };

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const branchData = data.branch.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.data;
    });

  const branchQuery = branchData[0];

  console.log(branchQuery);

  return (
    <>
      <section className={className} id="kontakty-brno">
        <Wrapper>
            <div className="branch-body-header">
              {branchQuery && branchQuery.kontakty_subtitle ? (
                <h3>{branchQuery.kontakty_subtitle.raw[0].text}</h3>
              ) : (
                ""
              )}
              <div className="branch-body-subtitle">
                {branchQuery && branchQuery.kontakty_obsah ? (
                  <p>{branchQuery.kontakty_obsah.raw[0].text}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="branch-body">
              <div className="branch-body-list">
                <div className="branch-body-title">
                  {branchQuery && branchQuery.kontakty_list_title ? (
                    <h4>{branchQuery.kontakty_list_title.raw[0].text}:</h4>
                  ) : (
                    ""
                  )}
                </div>
                <div className="branch-list-items">
                  {branchQuery && branchQuery.kontakty_list ? (
                    <>
                      {branchQuery.kontakty_list.map((item, index) => {
                        return (
                          <ol key={index} className="branch-list-single-item">
                            <span className="branch-list-logo">
                              <FaSquareFull />
                            </span>
                            <p className="branch-list-info">
                              {item.kontakty_item.raw[0].text}
                            </p>
                          </ol>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="branch-footer">
                  {branchQuery && branchQuery.kontakt_napsah ? (
                    <div className="branch-footer-title">
                      <h4>{branchQuery.kontakt_napsah.raw[0].text}</h4>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="branch-footer-info">
                    <div className="branch-kontakt">
                      {branchQuery.kontakt_adresa ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: branchQuery.kontakt_adresa.html,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_tel ? (
                        <p>{branchQuery.kontakt_tel.text}</p>
                      ) : (
                        ""
                      )}
                      {branchQuery.email ? (
                        <p className="email-links">
                          <a href={`mailto:${branchQuery.email.text}`}>
                            {" "}
                            {branchQuery.email.text}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_kontakt ? (
                        <div
                          style={{ marginTop: "30px" }}
                          dangerouslySetInnerHTML={{
                            __html: branchQuery.kontakt_kontakt.html,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_name_2 ? (
                        <p>{branchQuery.kontakt_name_2.text}</p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_tel_2 ? (
                        <p>{branchQuery.kontakt_tel_2.text}</p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_email_2 ? (
                        <p className="email-links">
                          <a
                            href={`mailto:${branchQuery.kontakt_email_2.text}`}
                          >
                            {" "}
                            {branchQuery.kontakt_email_2.text}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_name_3 ? (
                        <p>{branchQuery.kontakt_name_3.text}</p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_tel_3 ? (
                        <p>{branchQuery.kontakt_tel_3.text}</p>
                      ) : (
                        ""
                      )}
                      {branchQuery.kontakt_email_3 ? (
                        <p className="email-links">
                          <a
                            href={`mailto:${branchQuery.kontakt_email_3.text}`}
                          >
                            {" "}
                            {branchQuery.kontakt_email_3.text}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {branchQuery && branchQuery.kontakt_info ? (
                  <div>
                    {branchQuery.kontakt_info.map((item, index) => {
                      return (
                        <div key={index}>
                        {item.kontakt_obsah.raw ? (
                          <p>{item.kontakt_obsah.raw[0].text}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )} */}
                  </div>
                </div>
              </div>

              {branchQuery && branchQuery.kontakty_image_group ? (
                <div className="branch-body-img">
                  <Slider {...settings}>
                    {branchQuery.kontakty_image_group.map((item, index) => {
                      return (
                        <div key={index}>
                          <BackgroundImage
                            fluid={
                              item.kontakty_image.localFile.childImageSharp
                                .fluid
                            }
                            imgStyle={{ objectFit: "contain" }}
                            className="image"
                          >
                            <div className="image-banner">
                              {branchQuery && branchQuery.kontakt_napsah ? (
                                <div className="branch-footer-title">
                                  <h4>
                                    {branchQuery.kontakt_napsah.raw[0].text}
                                  </h4>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="branch-kontakt-mobile">
                                {branchQuery.kontakt_adresa ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: branchQuery.kontakt_adresa.html,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_tel ? (
                                  <p>{branchQuery.kontakt_tel.text}</p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.email ? (
                                  <p className="email-links-mobile">
                                    <a
                                      href={`mailto:${branchQuery.email.text}`}
                                    >
                                      {" "}
                                      {branchQuery.email.text}
                                    </a>
                                  </p>
                                ) : (
                                  ""
                                )}

                                {branchQuery.kontakt_kontakt ? (
                                  <div
                                    style={{ marginTop: "5px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: branchQuery.kontakt_kontakt.html,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_name_2 ? (
                                  <p>{branchQuery.kontakt_name_2.text}</p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_tel_2 ? (
                                  <p>{branchQuery.kontakt_tel_2.text}</p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_email_2 ? (
                                  <p className="email-links-mobile">
                                    <a
                                      href={`mailto:${branchQuery.kontakt_email_2.text}`}
                                    >
                                      {" "}
                                      {branchQuery.kontakt_email_2.text}
                                    </a>
                                  </p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_name_3 ? (
                                  <p>{branchQuery.kontakt_name_3.text}</p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_tel_3 ? (
                                  <p>{branchQuery.kontakt_tel_3.text}</p>
                                ) : (
                                  ""
                                )}
                                {branchQuery.kontakt_email_3 ? (
                                  <p className="email-links-mobile">
                                    <a
                                      href={`mailto:${branchQuery.kontakt_email_3.text}`}
                                    >
                                      {" "}
                                      {branchQuery.kontakt_email_3.text}
                                    </a>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* 
                          {branchQuery && branchQuery.kontakt_info ? (
                            <>
                              <div className="image-contact-info">
                                {branchQuery.kontakt_info.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <p className="image-contact-info-text">
                                        {item.kontakt_obsah.raw[0].text}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )} */}
                            </div>
                          </BackgroundImage>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                ""
              )}
            </div>
            {children}

        </Wrapper>
      </section>
    </>
  );
};

export default styled(BranchePraha)`
  text-align: center;
  overflow: hidden;

  .email-links {
    text-decoration: none !important;
    cursor: pointer;
    a {
      text-decoration: none !important;
      cursor: pointer;

      &:hover {
        text-decoration: none !important;
        background: none !important;
        font-weight: 700;
      }
    }
  }
  .email-links-mobile {
    color: white !important;
    text-decoration: none !important;
    cursor: pointer;
    a {
      color: white !important;
      text-decoration: none !important;
      cursor: pointer;

      &:hover {
        text-decoration: none !important;
        background: none !important;
        font-weight: 700;
      }

      &:focus {
        text-decoration: none !important;
        background: none !important;
        font-weight: 700;
      }
    }
  }

  .branch-body-subtitle {
    text-align: justify;
  }

  .slick-prev:before {
    content: none;
  }

  .slick-next:before {
    content: none;
  }

  .branch-kontakt {
    a {
      color: white;
    }
  }

  .branch-kontakt-mobile {
    a {
      color: white !important;
      text-decoration: none !important;
      cursor: pointer;
    }
  }

  background-color: ${theme.colors.primary};

  .branch-body-header {
    margin-top: 50px;
  }

  .branch-body-header {
    padding-top: 50px;
    margin: 0 auto;
    width: 80%;
  }

  .branch-body-header h3 {
    color: white;
    margin-bottom: 30px;
  }

  .branch-body-header p {
    color: white;
    margin-bottom: 50px;
  }

  .branch-body-title {
    margin-left: 0px;
    text-align: left;
  }

  .branch-body-title h4 {
    margin-bottom: 30px;
    font-weight: 700;
  }

  .branch-body-list ol {
    color: white;
  }

  .branch-body-list h4 {
    color: white;
  }

  .branch-body-list {
    text-align: left;
    color: ${theme.colors.primary};
  }

  .branch-body-img {
    opacity: 0.9;
  }

  .image {
    width: 100%;
    height: 450px;
    background-position: center;
    background-size: cover;
    opacity: 1 !important;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  }

  .image-banner {
    width: 336px;
    text-align: center;
    margin: 0 auto;
    padding-top: 39px;
  }

  .image-banner h4 {
    color: white;
    border-bottom: 1px solid;
    padding-bottom: 10px;
  }

  .image-banner p {
    color: white;
    margin-top: 20px;
  }

  .branch-list-logo {
    padding-right: 5px;
    font-size: 10px;
  }

  .branch-body-list {
    margin-left: 18px;
  }

  .branch-list-logo {
    font-size: 19px;
  }

  .branch-body-list ol {
    margin-left: 0;
  }

  .branch-footer {
    display: none;
  }

  .branch-footer p {
    color: white;
    margin-bottom: 10px;
  }

  .branch-list-single-item {
    display: flex;
    align-items: baseline;
  }

  .branch-list-info {
    padding-left: 5px;
    color: white;
  }

  .image-contact-info p {
    margin-bottom: 0;
  }
  @media (min-width: 1025px) {
    padding-bottom: 40px;
    .branch-body-subtitle {
      width: 95%;
    }

    .branch-body-title h4 {
      margin-bottom: 30px;
    }

    .branch-body-subtitle p {
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: justify;
    }

    .branch-body-header {
      margin-left: 200px;
      text-align: left;
    }

    .branch-body-header h3 {
      // border-bottom: 1px solid;
      padding-top: 20px;
      font-weight: 700;
    }

    .branch-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
    }

    .branch-body-img {
      opacity: 0.9;
      width: 45vw;
      height: 402.12px;
    }

    .image {
      background-position: center;
      background-size: cover;
      /* opacity: 1 !important; */
      box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.18),
        0px 4px 4px rgba(0, 0, 0, 0.25);
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    }

    .branch-list-items {
      width: 95%;
    }

    .image-banner {
      display: none;
    }

    .branch-body-list {
      margin-left: 200px;
    }

    .branch-list-logo {
      padding-right: 5px;
      font-size: 10px;
    }

    .branch-list-info {
      margin-bottom: 0;
    }

    .branch-body-list ol {
      font-size: 18px;
      margin-left: 0;
    }

    .branch-footer {
      width: 80%;
      display: block;
    }

    .branch-footer-info p {
      margin-bottom: 5px;
    }

    .branch-footer h4 {
      border-top: 1px solid;
      padding-top: 10px;
      margin-bottom: 20px;
      padding-top: 40px;
    }
  }
`;
